<template>
    <div
        @mouseover="state.status = true"
        @mouseleave="state.status = false"
        class="card">
        <div class="card-body">
            <div class="row justify-content-end" style="margin-bottom:-2rem;">
                <div class="col-lg-6 col-md-12 py-2">
                    <strong>{{ title }}</strong>
                </div>
                <div class="col-lg-6 col-md-12 text-right">
                    <ASpace v-if="state.status">
                        <slot name="button"/>
                    </ASpace>
                </div>
            </div>
            <slot/>
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive } from 'vue'
export default defineComponent({
    props: {
        title: {
            default: '',
            type: String,
        },
    },
    setup: ( props ) => {
        const state = reactive({
            status: false,
        })

        return {
            state,
        }
    },
})
</script>
