<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <div class="row">
            <div class="col-md-12">
                <ADatePicker
                    class="mr-2"
                    v-model:value="state.params.start_date"
                    :disabled-date="disabledStartDate"
                    placeholder="Mulai Tanggal"
                    style="width:300px;"
                    format="DD MMMM YYYY"/>
                <ADatePicker
                    class="mr-2 mt-2"
                    v-model:value="state.params.end_date"
                    :disabled-date="disabledEndDate"
                    placeholder="Akhir Tanggal"
                    style="width:300px;"
                    format="DD MMMM YYYY"/>
                <FilterDistributor
                    class="mr-2"
                    v-can:hide.distributor
                    v-model:value="state.params.distributor"
                    v-model:region="state.params.region"
                    v-model:area="state.params.area"
                    v-model:kabupaten="state.params.kabupaten"/>
                <FilterArea
                    class="mr-2"
                    v-can:hide.distributor
                    v-model:value="state.params.area"/>
            </div>
            <div class="col-md-12 mt-2">
                <FilterRegional
                    class="mr-2"
                    v-can:hide.distributor
                    v-model:value="state.params.region"/>
                <FilterProvince
                    class="mr-2"
                    v-model:value="state.params.provinsi"
                    v-model:region="state.params.region"/>
                <AButton
                    class="mr-2"
                    type="primary"
                    title="cari"
                    @click="btnFetchData()"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </div>
        </div>

        <div class="row justify-content-end mt-5">
            <div class="col-lg-4 col-md-12 text-center">
                <div class="card">
                    <div class="card-body my-5">
                        <ASkeleton
                            active
                            :paragraph="{ rows: 2 }"
                            :loading="state.transaction_all_reginal.loading || state.transaction_topten_area.loading">
                            <h1 class="display-3">{{ FormatNumber(state.total.transaction_total) }}</h1>
                            <strong>Total Transaksi di MDXL</strong>
                        </ASkeleton>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12 text-center">
                <div class="card">
                    <div class="card-body my-5">
                        <ASkeleton
                            active
                            :paragraph="{ rows: 2 }"
                            :loading="state.transaction_all_reginal.loading || state.transaction_topten_area.loading">
                            <h1 class="display-3">{{ FormatNumber(state.total.transaction_offline) }}</h1>
                            <strong>Total Transaksi Offline di MDXL</strong>
                        </ASkeleton>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12 text-center">
                <div class="card">
                    <div class="card-body my-5">
                        <ASkeleton
                            active
                            :paragraph="{ rows: 2 }"
                            :loading="state.transaction_all_reginal.loading || state.transaction_topten_area.loading">
                            <h1 class="display-3">{{ FormatNumber(state.total.transaction_aksestoko) }}</h1>
                            <strong>Total Transaksi Online via AksesToko</strong>
                        </ASkeleton>
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-end mt-5">
            <div class="col-lg-6 col-md-12 mt-auto">
                <MCard title="Transaksi All Regional MDXL">
                    <template #button>
                        <DownloadExcel
                            :url="state.endpoint.transaction_all_reginal"
                            :params="state.params"
                            namefile="Transaksi-All-Regional-MDXL"
                            @errors="errorMessage" />
                    </template>
                    
                    <EChartCustom
                        :labelx="state.transaction_all_reginal.labelx"
                        :series="state.transaction_all_reginal.series"
                        :loading="state.transaction_all_reginal.loading"/>
                </MCard>
            </div>
            <div class="col-lg-6 col-md-12 mt-auto">
                <MCard title="Top Ten Area Transaksi MDXL">
                    <template #button>
                        <DownloadExcel
                            :url="state.endpoint.transaction_topten_area"
                            :params="state.params"
                            namefile="Top-Ten-Area-Transaksi-MDXL"
                            @errors="errorMessage" />
                    </template>
                    
                    <EChartCustom
                        :labelx="state.transaction_topten_area.labelx"
                        :series="state.transaction_topten_area.series"
                        :loading="state.transaction_topten_area.loading"/>
                </MCard>
            </div>
        </div>

        <div class="card">
            <div class="card-body text-center">
                <strong>BREAKDOWN TRANSAKSI OFFLINE MDXL</strong>
            </div>
        </div>

        <div class="row justify-content-end mt-5">
            <div class="col-lg-4 col-md-12 text-center" style="display:flex;flex-direction:column;">
                <div class="card">
                    <div class="card-body">
                        <ASkeleton
                            active
                            :paragraph="{ rows: 2 }"
                            :loading="state.total.loading">
                            <h1>{{ FormatCurrency(state.total.price_offline) }}</h1>
                            <strong>Summary Price Transaksi Offline MDXL</strong>
                        </ASkeleton>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <ASkeleton
                            active
                            :paragraph="{ rows: 2 }"
                            :loading="state.total.loading">
                            <h1>{{ FormatCurrency(state.total.tonase_offline) }}</h1>
                            <strong>Summary Tonase Transaksi Offline MDXL</strong>
                        </ASkeleton>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 col-md-12 mt-auto">
                <MCard title="Brand Contribution Offline Transaction MDXL">
                    <template #button>
                        <DownloadExcel
                            :url="state.endpoint.contribution_brand_offile"
                            :params="state.params"
                            namefile="Brand-Contribution-Offline-Transaction-MDXL"
                            @errors="errorMessage" />
                    </template>
                    
                    <EChartCustom
                        :labely="state.contribution_brand_offile.labely"
                        :series="state.contribution_brand_offile.series"
                        :loading="state.contribution_brand_offile.loading"/>
                </MCard>
            </div>
        </div>

        <div class="card">
            <div class="card-body text-center">
                <strong>BREAKDOWN TRANSAKSI ONLINE MDXL</strong>
            </div>
        </div>

        <div class="row justify-content-end mt-5">
            <div class="col-lg-4 col-md-12 text-center" style="display:flex;flex-direction:column;">
                <div class="card">
                    <div class="card-body">
                        <ASkeleton
                            active
                            :paragraph="{ rows: 2 }"
                            :loading="state.total.loading">
                            <h1>{{ FormatCurrency(state.total.price_online) }}</h1>
                            <strong>Summary Price Transaksi Online Via AT MDXL</strong>
                        </ASkeleton>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <ASkeleton
                            active
                            :paragraph="{ rows: 2 }"
                            :loading="state.total.loading">
                            <h1>{{ FormatCurrency(state.total.tonase_online) }}</h1>
                            <strong>Summary Tonase Transaksi Online Via AT MDXL</strong>
                        </ASkeleton>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 col-md-12 mt-auto">
                <MCard title="Brand Contribution Online Via AT Transaction MDXL">
                    <template #button>
                        <DownloadExcel
                            :url="state.endpoint.contribution_brand_online"
                            :params="state.params"
                            namefile="Brand-Contribution-Online-Via-AT-Transaction-MDXL"
                            @errors="errorMessage" />
                    </template>
                    
                    <EChartCustom
                        :labely="state.contribution_brand_online.labely"
                        :series="state.contribution_brand_online.series"
                        :loading="state.contribution_brand_online.loading"/>
                </MCard>
            </div>
        </div>
        
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, watch, onBeforeMount } from 'vue'
import apiClient from '@/services/axios'
import { Modal, Space, message } from 'ant-design-vue'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterArea from '@/components/filter/FilterArea'
import FilterProvince from '@/components/filter/FilterProvince'
import EChartCustom from '@/components/charts/EChartCustom'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import MCard from '@/components/Molecules/MCard'
import { debounce, pickBy, sum } from 'lodash'
import moment from 'moment'
import { FormatCurrency, FormatNumber } from '@/helpers/utils'

export default defineComponent({
    components: {
        FilterDistributor,
        FilterRegional,
        FilterArea,
        FilterProvince,
        EChartCustom,
        DownloadExcel,
        MCard,
    },
    setup() {
        const errorMessage = ref()

        const state = reactive({
            endpoint: {
                transaction_all_reginal: '/api/report/metabase-v2/transaksi-all-regional-mdxl',
                transaction_topten_area: '/api/report/metabase-v2/top-ten-area-mdxl',
                contribution_brand_offile: '/api/report/metabase-v2/brand-contribution-offline-mdxl',
                contribution_brand_online: '/api/report/metabase-v2/brand-contribution-online-mdxl',
            },
            params: {
                q: '',
                region: [],
                distributor: [],
                area: [],
                kabupaten: [],
                provinsi: [],
                start_date: moment(new Date()).startOf('month'),
                end_date: moment(new Date()).subtract(0, 'days'),
                page: 1,
                "per-page": 10,
            },
            transaction_all_reginal: {
                loading: false,
                labelx: [],
                series: [],
            },
            transaction_topten_area: {
                loading: false,
                labelx: [],
                series: [],
            },
            contribution_brand_offile: {
                loading: false,
                labely: [],
                series: [],
            },
            contribution_brand_online: {
                loading: false,
                labely: [],
                series: [],
            },
            total: {
                loading: false,
                price_offline: 0,
                price_online: 0,
                tonase_offline: 0,
                tonase_online: 0,
                transaction_total: 0,
                transaction_offline: 0,
                transaction_aksestoko: 0,
            },
            isCard: false,
        })

        const disabledStartDate = (current) => {
            return current && current > moment(state.params.end_date)
        }

        const disabledEndDate = (current) => {
            return current && moment(state.params.start_date) >= current
        }

        const queryParams = () => {
            let params = state.params

            if (state.params.start_date) {
                state.params.start_date = moment(state.params.start_date).format('YYYY-MM-DD')
            }

            if (state.params.end_date) {
                state.params.end_date = moment(state.params.end_date).format('YYYY-MM-DD')
            }

            return pickBy(params)
        }

        // fetch transaction all regional
        const fetchTransactionAllReginalList = () => {
            state.transaction_all_reginal.loading = true
            
            apiClient
                .get(state.endpoint.transaction_all_reginal, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    state.transaction_all_reginal.labelx = [{
                        type: 'category',
                        data: data.map(item => item.customer_regional),
                    }]

                    state.transaction_all_reginal.series = [
                        {
                            name: 'Total',
                            type: 'bar',
                            emphasis: {
                                focus: 'series',
                            },
                            color: '#f9d45c',
                            barGap: 0,
                            data: data.map(item => item.total),
                        },
                        {
                            name: 'Total Offline',
                            type: 'bar',
                            emphasis: {
                                focus: 'series',
                            },
                            color: '#519ee3',
                            barGap: 0,
                            data: data.map(item => item.total_offline),
                        },
                        {
                            name: 'Total Aksestoko',
                            type: 'bar',
                            emphasis: {
                                focus: 'series',
                            },
                            color: '#ee8c8b',
                            barGap: 0,
                            data: data.map(item => item.total_aksestoko),
                        },
                    ]
                })
                .finally(() => {
                    state.transaction_all_reginal.loading = false
                })
        }

        // fetch transaction topten area
        const fetchTransactionToptenAreaList = () => {
            state.transaction_topten_area.loading = true
            
            apiClient
                .get(state.endpoint.transaction_topten_area, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    state.transaction_topten_area.labelx = [{
                        type: 'category',
                        data: data.map(item => item.customer_area),
                    }]

                    state.transaction_topten_area.series = [
                        {
                            name: 'Total',
                            type: 'bar',
                            emphasis: {
                                focus: 'series',
                            },
                            color: '#f9d45c',
                            barGap: 0,
                            data: data.map(item => item.total),
                        },
                        {
                            name: 'Total Offline',
                            type: 'bar',
                            emphasis: {
                                focus: 'series',
                            },
                            color: '#519ee3',
                            barGap: 0,
                            data: data.map(item => item.total_offline),
                        },
                        {
                            name: 'Total Aksestoko',
                            type: 'bar',
                            emphasis: {
                                focus: 'series',
                            },
                            color: '#ee8c8b',
                            barGap: 0,
                            data: data.map(item => item.total_aksestoko),
                        },
                    ]
                })
                .finally(() => {
                    state.transaction_topten_area.loading = false
                })
        }

        // fetch contribution brand offline
        const fetchContributionBrandOfflineList = () => {
            state.contribution_brand_offile.loading = true
            
            apiClient
                .get(state.endpoint.contribution_brand_offile, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    state.contribution_brand_offile.labely = [{
                        type: 'category',
                        data: data.map(item => item.brand_name),
                    }]

                    state.contribution_brand_offile.series = [{
                        name: 'Total',
                        type: 'bar',
                        color: '#ee8c8b',
                        data: data.map(item => item.total_offline),
                    }]
                })
                .finally(() => {
                    state.contribution_brand_offile.loading = false
                })
        }

        // fetch contribution brand online
        const fetchContributionBrandonlineList = () => {
            state.contribution_brand_online.loading = true
            
            apiClient
                .get(state.endpoint.contribution_brand_online, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    state.contribution_brand_online.labely = [{
                        type: 'category',
                        data: data.map(item => item.brand_name),
                    }]

                    state.contribution_brand_online.series = [{
                        name: 'Total',
                        type: 'bar',
                        color: '#ee8c8b',
                        data: data.map(item => item.total_aksestoko),
                    }]
                })
                .finally(() => {
                    state.contribution_brand_online.loading = false
                })
        }

        // fetch total summary transactuon
        const fetchTotalSummaryTransactionList = () => {
            state.total.loading = true
            
            apiClient
                .get('/api/report/metabase-v2/summary-all-transaction', {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    data.forEach(item => {
                        state.total.price_offline = item.total_price_offline
                        state.total.price_online = item.total_price_online
                        state.total.tonase_offline = item.total_tonase_offline
                        state.total.tonase_online = item.total_tonase_online

                        state.total.transaction_total = item.total_transaksi_mdxl
                        state.total.transaction_offline = item.total_offline_mdxl
                        state.total.transaction_aksestoko = item.total_online_aksestoko
                    })
                })
                .finally(() => {
                    state.total.loading = false
                })
        }

        const btnFetchData = () => {
            fetchTransactionAllReginalList()
            fetchTransactionToptenAreaList()
            fetchContributionBrandOfflineList()
            fetchContributionBrandonlineList()
            fetchTotalSummaryTransactionList()
        }

        // handle vue
        onMounted(() => {
            btnFetchData()
        })

        return {
            btnFetchData,
            state,
            errorMessage,
            disabledStartDate,
            disabledEndDate,
            FormatNumber,
            FormatCurrency,
        }
    },
})
</script>

<style lang="scss" scoped>
.card {
    height: 100%;
    .card-body {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
}
</style>